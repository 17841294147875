@font-face {
    font-family: 'Gilroy';
    src: local('Gilroy Semibold'), local('Gilroy-Semibold'),
        url('Gilroy-Semibold.woff2') format('woff2'),
        url('Gilroy-Semibold.woff') format('woff'),
        url('Gilroy-Semibold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: local('Gilroy Regular'), local('Gilroy-Regular'),
        url('Gilroy-Regular.woff2') format('woff2'),
        url('Gilroy-Regular.woff') format('woff'),
        url('Gilroy-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: local('Gilroy Light'), local('Gilroy-Light'),
        url('Gilroy-Light.woff2') format('woff2'),
        url('Gilroy-Light.woff') format('woff'),
        url('Gilroy-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}
body {
  margin: 0px;
  background-color: #141425;
  max-width: 425px;
  margin: 0 auto;
}

div#root {
  max-width: 425px;
  overflow: hidden;
}

.bonuses {
  margin: 0 auto;
  margin-top: 50px;
  position: relative;
}

.header {
  height: 36px;
  background: #1f1a36;
  max-width: 425px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.header__logo-container {
  display: flex;
  align-items: center;
  overflow: hidden;
  width: 450%;
  height: 100%;
}

.header__logo {
  display: inline-block;
  height: 18px;
  margin-left: 10px;
}

.progress {
  max-width: 425px;
  position: relative;
  overflow: hidden;
  padding-bottom: 50px;
}

.progress__title {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #ffffff;
  margin: 0;
}

.progress__procent {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #ffffff;
  margin: 0;
}

.progress__info {
  display: flex;
  justify-content: space-between;
  margin: 10px 12px 0px 12px;
}

.progress__line {
  width: 276px;
  height: 7px;
  background: linear-gradient(
    180deg,
    rgba(136, 87, 231, 0.3) 0%,
    rgba(110, 60, 207, 0.3) 100%
  );
  border-radius: 100px;
  position: absolute;
  z-index: 1;
  margin-top: 12px;
}
.progress__line_block {
  width: 276px;
  margin: 0 auto;
}

.progress__block {
  height: 74px;
  max-width: 300px;
  margin: 0 auto;
  margin-top: 50px;
  background: #1f1a36;
  box-shadow: 0px 4px 60px rgb(158 0 255 / 20%);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}

.progress__line_active {
  max-width: 276px;
  height: 7px;
  background: linear-gradient(180deg, #a14bd6 0%, #672392 100%);
  border-radius: 100px;
  position: relative;
  z-index: 2;
  transition: width 1.25s;
  margin-top: 12px;
}

.bonus__line {
  width: 172px !important;
}

.modules {
  margin: 0 auto;
  position: relative;
}

.modules__overflow {
  overflow-x: auto;
  max-width: 425px;
  margin-top: 10px;
}

.modules__title {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 34px;
  margin: 0;
  color: #ffffff;
}

.modules__title_position {
  width: 300px;
  margin: 0 auto;
}

.modules__card {
  display: flex;
  height: 190px;
  align-items: center;
  width: 300px;
  margin: 0 auto;
}

.modules__link {
  position: relative;
  width: 229px;
  /* top: 50%; */
  margin-right: 40px;
}

.modules__block {
  position: relative;
}

.modules__dnk {
  width: 105px;
  position: absolute;
  top: 15%;
  right: 5px;
  rotate: -10deg;
}

.modules__water {
  padding: 20px;
}

.bonus__image {
  position: absolute;
  height: 101.65px;
  left: 0px;
  top: 40px;
  z-index: -1;
  rotate: 15deg;
}

.modules__card_img {
  width: 229px;
  height: 136px;
  margin-right: 30px;
  box-shadow: 0px 4px 30px rgb(158 0 255 / 20%);
  border-radius: 20px;
  z-index: 1;
}

.modules__line {
  width: 80px;
  height: 4px;
  background-color: #b045f2;
  margin-top: -12px;
}

.rating {
  margin: 0 auto;
  margin-top: 50px;
  height: 385px;
  width: 300px;
  background: #1f1a36;
  box-shadow: 0px 4px 60px rgb(158 0 255 / 20%);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.rating__image_mini {
  position: absolute;
  width: 120px;
  z-index: -1;
  right: -60px;
  top: -40px;
  animation: up-down2 2s ease-in-out infinite;
}

@keyframes up-down2 {
  0% {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(0, 20px);
  }

  100% {
    transform: translate(0, 0);
  }
}

.rating__name_info {
  display: flex;
  align-items: center;
}

.rating__image {
  position: absolute;
  bottom: -45px;
  left: -100px;
  width: 172px;
  z-index: -1;
  animation: up-down 2s ease-in-out infinite;
}

@keyframes up-down {
  0% {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(0, -20px);
  }

  100% {
    transform: translate(0, 0);
  }
}

.rating__title {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 34px;
  color: #ffffff;
  margin: 0;
  margin-top: 18px;
  margin-left: 16px;
}

.rating__card_title {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  display: flex;
  align-items: center;
  color: #ffffff;
  margin: 0;
  margin-left: 15px;
  margin-top: -2px;
}

.rating__card_result {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  display: flex;
  align-items: center;
  text-align: right;
  color: #ffffff;
  margin: 0;
}

.rating__card {
  margin: 0px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 274px;
  border-bottom: 1px solid #ffffff75;
  height: 65px;
}

.rating__card_avatar {
  height: 32px;
  width: 32px;
}

.rating__card_money {
  height: 28px;
  width: 28px;
  margin-top: -2px;
}

.rating__card_money-info {
  display: flex;
  align-items: center;
}

.button__main {
  margin: 0 auto;
  width: 300px;
  margin-top: 50px;
  cursor: pointer;
}

.button {
  background: linear-gradient(180deg, #c669ff 0%, #8e27cd 100%);
  border-radius: 30px;
  width: 300px;
  height: 63px;
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #ffffff;
  border: none;
  cursor: pointer;
}

.footer {
  margin: 0 auto;
  margin-top: 20px;
  max-width: 425px;
  height: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  justify-content: center;
}

.footer__name {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #c7c7c7;
  margin: 0;
}

.footer__iamge {
  z-index: -1;
  position: absolute;
  width: 250px;
  top: -70px;
  right: -50px;
  rotate: 40deg;
}

.rating__card:last-child {
  border: none;
}

.material__img_title {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  display: flex;
  align-items: center;
  width: 100px;
  color: #ffffff;
  /* margin-left: -41px;
  margin-top: -60px; */
  position: absolute;
  top: 45%;
}

.material__line {
  position: absolute;
  /* right: 20px;
  top: 50px; */
  left: -5px;
  top: 40px;
  width: 55px;
}

.modules__material {
  display: flex;
  position: relative;
  margin-top: 35px;
}

.lessons__line_widht {
  width: 64px;
}

.material__line_widht {
  width: 166px;
}

.lessons__header {
  margin: 0 auto;
  height: 700px;
  max-width: 425px;
  display: flex;
  background-size: cover;
  flex-direction: column;
  justify-content: flex-end;
}

.lessons__number {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  /* or 156% */
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
}

.lessons__color_sqwear {
  background: #b045f2;
  border-radius: 6px;
  min-width: 103px;
  max-width: 110px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 36px;
  justify-content: center;
}

.lessons__info_title {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  /* or 130% */
  width: 240px;
  margin: 0;
  margin-top: 10px;

  color: #ffffff;
}

.lessons__info_text {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  margin: 0;
  margin-top: 7px;
  margin-bottom: 20px;

  color: #ffffff;
}

.lessons_button_watch {
  width: 120px;
  height: 43px;
  background: #b045f2;
  border-radius: 30px;
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 19px;
  cursor: pointer;
  border: none;
  margin-right: 10px;
  color: #ffffff;
}
.lessons_button_watch-android {
  width: 150px;
  height: 43px;
  background: #b045f2;
  border-radius: 30px;
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 19px;
  cursor: pointer;
  border: none;
  margin-bottom: 15px;
  color: #ffffff;
}
.lessons_button_back {
  width: 94px;
  height: 43px;
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 19px;
  cursor: pointer;
  background: #181830;
  border: 1px solid #b045f2;
  border-radius: 30px;
  color: #ffffff;
  margin-right: 10px;
}

.lessons_button_homework {
  width: 106px;
  height: 43px;
  background: #fff;
  border-radius: 30px;
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 19px;
  cursor: pointer;
  border: none;
  color: #b045f2;
  margin-right: 10px;
}

.lessons__buttons {
  width: 300px;
  cursor: pointer;
}

.lessons__info {
  width: 285px;
}

.lessons__container {
  max-width: 300px;
  margin: 0 auto;
  margin-bottom: 30px;
}

.lesseons__header_img {
  position: absolute;
  max-width: 425px;
  z-index: -1;
}

.video-player {
  width: 300px;
  height: 300px;
  background: #c7c7c7;
}

.video-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.video-modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
}

.video-modal-content {
  position: relative;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
}

.modal-close {
  position: absolute;
  top: 38%;
  right: 55px;
  cursor: pointer;
}

.video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #12121e;
  z-index: 9999;
}

.video__blcok {
  width: 100%;
}

.materials {
  width: 300px;
  margin: 0 auto;
  margin-top: 30px;
}

.nextlessons__line {
  width: 192px !important;
}

.calendar {
  width: 300px;
  margin: 0 auto;
  margin-top: 50px;
}

.calendar__line {
  width: 2px;
  height: 50px;
  background: #8857e7;
  border-radius: 12px;
}

.calendar__title {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 34px;
  margin: 0;
  color: #ffffff;
  margin-bottom: 30px;
}

.calendar__lines {
  width: 175px;
}

.calendar__image {
  z-index: -1;
  position: relative;
  margin-top: 30px;
}

.calendar__date {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  color: #ffffff;
}

.calendar__table {
  margin-top: -70px;
  z-index: 1;
}

.calendar__info {
  display: flex;
  width: 300px;
  margin: 0 auto;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 10px;
  background: #1f1a36;
  box-shadow: 0px 4px 40px rgb(158 0 255 / 20%);
  border-radius: 10px;
}

.calendar__text {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  color: #ffffff;
}

.header__smoke {
  position: absolute;
  width: 360px;
  height: 200px;
  z-index: -1;
  rotate: 30deg;
  top: -40px;
  right: -120px;
}

.timer {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 32px;
  /* identical to box height, or 324px */
  color: white;
  letter-spacing: 0.07em;
  text-transform: uppercase;
  position: absolute;
  top: 45%;
  left: 15px;
}

.footer__rights {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 17px;
  color: #c7c7c7;
  margin: 0;
  margin-top: -2px;
}

.materials__circle {
  width: 100px;
  height: 100px;
  background: #b045f2;
  border-radius: 50%;
  filter: blur(40px);
  position: absolute;
  top: 25%;
  right: 10px;
  z-index: -1;
}

.material__img {
  margin-left: 50px;
  width: 235px;
}

.lessons__button {
  background: linear-gradient(180deg, #c669ff 0%, #8e27cd 100%);
  border-radius: 30px;
  width: 91px;
  height: 31px;
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #ffffff;
  border: none;
}

.fsb {
  display: flex;
  justify-content: space-between;
}

.fadeOut {
  animation: fadeOut 1s;
}

@keyframes fadeOut {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.next__lessons {
  margin-top: 40px;
}

.score {
  width: 300px;
  margin: 0 auto;
  margin-top: 30px;
}

.score__input {
  -webkit-appearance: none;
  width: 280px;
  margin: 0 auto;
  margin-top: 8px;
  background: linear-gradient(
    180deg,
    rgba(136, 87, 231, 0.3) 0%,
    rgba(110, 60, 207, 0.3) 100%
  );
  /* background: linear-gradient(180deg, #A14BD6 0%, #672392 100%); */
  border-radius: 15px;
  overflow: hidden;
}

input[type="range"]::-webkit-slider-runnable-track {
  height: 11px;
  background: linear-gradient(
    180deg,
    rgba(136, 87, 231, 0.3) 0%,
    rgba(110, 60, 207, 0.3) 100%
  );

  border-radius: 16px;
}

.score__input::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: none;
  height: 11px;
  width: 11px;
  border-radius: 50%;
  background: #fff;
  box-shadow: -407px 0 0 400px #a14bd6;
}

.footer__link {
  color: #c669ff;
  text-decoration: none;
}

.modal-close__img {
  width: 34px;
  height: 34px;
}

.modules__overflow::-webkit-scrollbar {
  height: 15px !important;
}

.modules__overflow::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1) !important;
  border-radius: 10px !important;
}

.modules__overflow::-webkit-scrollbar-thumb {
  border-radius: 10px !important;
  background-color: rgba(161, 75, 214, 0.7) !important;
}

.iframe__phone {
  height: 60vh;
  width: 90vh;
  rotate: 90deg;
}
